<template>
    <v-container>
        <div class="text-center">
            <v-progress-circular :size="70" :width="7" indeterminate color="indigo">
                <v-img
                :lazy-src="logoLoading"
                max-height="40"
                max-width="40"
                :src="logoLoading"
                ></v-img>
            </v-progress-circular>
        </div>
    </v-container>
</template>

<script>
import logo from "../assets/favicon-32x32.png";
export default {
    name: "Line",
    data() {
        return {
            logoLoading: logo,
        }
    },
    methods: {
        lineSuccess() {
            let code = this.$router.history.current.query.code
            let state = this.$router.history.current.query.state
            this.$store.dispatch('auth_module/lineCallback', { code: code, state: state })
        }
    },
    created() {
        this.lineSuccess()
    },
    
}
</script>

<style>

</style>